import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';
import { param } from 'jquery';

// Action Types
const SET_INITIAL = 'config/SET_INITIAL';
const SERVICE_CONFIG_INITIAL_BASIC = 'config/SERVICE_CONFIG_INITIAL_BASIC';
const GET_SERVICE_CONFIG = 'config/GET_SERVICE_CONFIG';
const UPDATE_SERVICE_CONFIG = 'config/UPDATE_SERVICE_CONFIG';
const CHECK_SMTP = 'config/CHECK_SMTP';
const GET_MASKING_CONFIG = 'config/GET_MASKING_CONFIG';

// Actions
export const serviceConfigInitBasic = (params: { [key: string]: string }) =>
  actionFormat(SERVICE_CONFIG_INITIAL_BASIC, params, 'put', `${execUrl}ServiceConfigInit`);
export const getServiceConfig = (params: any) =>
  actionFormat(GET_SERVICE_CONFIG, params, 'get', `${apiUrl}ServiceConfig`);
export const updateServiceConfig = (params: { [key: string]: any }) =>
  actionFormat(UPDATE_SERVICE_CONFIG, params, 'put', `${apiUrl}ServiceConfig`);
export const userCheckSmtp = () => actionFormat(CHECK_SMTP, null, 'get', `${execUrl}CheckSmtp`);
export const getMaskingConfig = (params?: { [key: string]: any }) =>
  actionFormat(GET_MASKING_CONFIG, params, 'get', `${apiUrl}SystemConfig/Masking`);

// initial state
const initialState = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  indexByNo: {},
  needReload: false,
  smtpConfig: {},
  eduConfig: {},
  declareConfig: {},
  notificationConfig: {},
  etcConfig: {},
  isMasking: false,
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case SERVICE_CONFIG_INITIAL_BASIC:
      return initialState;
    case GET_SERVICE_CONFIG:
      if (action?.payload?.config?.params?.category) {
        const normalizedData: { [key: string]: any } = {
          parameter: {},
          class: {},
          classOrder: [],
        };
        const { category } = action.payload.config.params;
        const { list } = action.payload.data;

        const parameterOrder = getFollowOrderArray(list, 'parameter', 'followOrder');

        const seenClass: { [key: string]: any } = {};
        for (let i = 0; i < parameterOrder.length; i += 1) {
          if (typeof list[parameterOrder[i]] === 'undefined') {
            i += 1;
          }
          const config = list[parameterOrder[i]];

          normalizedData.parameter[config.parameter] = config;
          normalizedData.parameter[config.parameter].beforeValue = config.value;

          if (config.paramType !== 0) {
            if (typeof seenClass[config.class] === 'undefined') {
              normalizedData.classOrder.push(config.class);
              normalizedData.class[config.class] = [];
              seenClass[config.class] = 1;
            }
            normalizedData.class[config.class].push({
              parameter: config.parameter,
              parameterNotice: config.parameterNotice,
              followOrder: config.followOrder,
              paramType: config.paramType,
            });
          } else {
            // 메일 서버 테스트 파라메터
          }
        }
        state = {
          ...state,
          [`${category}Config`]: normalizedData,
        };
      }
      return state;
    case UPDATE_SERVICE_CONFIG:
      if (action?.payload) {
        const { list } = action.payload.data;
        const { category, parameter, value } = list[0];
        const configCategory = `${category}Config`;
        // if (category === 'smtp') {
        //   configCategory = 'smtpConfig';
        // } else if (category === 'edu') {
        //   configCategory = 'eduConfig';
        // } else {
        //   configCategory = 'declareConfig';
        // }
        if (state[configCategory].parameter === undefined) {
          state[configCategory] = { parameter: { [parameter]: { beforeValue: 0 } } };
        }

        state[configCategory].parameter[parameter].beforeValue = value;
        state[configCategory].parameter[parameter].value = value;
      }
      return state;
    case CHECK_SMTP:
      if (action?.payload) {
        const { list } = action.payload.data;

        state.smtpConfig.parameter.mailSmtpTested = list;
      }
      return state;
    case GET_MASKING_CONFIG:
      if (action?.payload?.data?.list) {
        const { parameter, value } = action.payload.data.list[0];
        state.isMasking = parameter === 'userTargetMasking' && value === '1';
      }
      return state;
    default:
      return state;
  }
}
