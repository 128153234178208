import { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Select, Tag } from 'antd';
import { getEduSummary, changeSummaryState, changeTargetState, putEdu, deleteEdu } from 'store/edu';
import { getExam } from 'store/startExam';
import { nowEpoch, epochFromDate, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import EduSidebarItem from 'components/branch/edu/EduSidebarItem';
import FormDatePicker from 'components/common/FormDatePicker';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import './EduSidebar.scss';

// import allImage from 'img/exam/exam_all.png';
// import searchImage from 'img/exam/search.png';
// import cancelImage from 'img/exam/search_cancel.png';
// import icoSearchImage from 'img/tag/ico_search.png';

function EduSidebar() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const eduSummary = useSelector((state: any) => {
    return {
      data: state.edu.eduSummary.data,
      dataByEduNo: state.edu.eduSummary.dataByEduNo,
      param: state.edu.eduSummary.param,
      needReload: state.edu.eduSummary.needReload,
      selectedEduNo: state.edu.eduSummary.selectedEduNo,
    };
  }, shallowEqual);
  const examData = useSelector((state: any) => state.startExam.exam);
  const userData = useSelector((state: any) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  // 검색 버튼/창 토글
  const [searchMode, setSearchMode] = useState(false);
  // 검색어 필터
  const [searchText, setSearchText] = useState('');
  const [openModalTarget, setOpenModalTarget] = useState<any>({ target: '', eduData: {} });
  const [values, setValues]: any = useState({
    eduName: '',
    startEpoch: null,
    endEpoch: null,
    examNoArray: [],
  });
  const history = useHistory();

  const { register, control, errors, setError, setValue, clearErrors, handleSubmit } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    onGetEduSummary();
  }, [eduSummary.param, eduSummary.needReload]);

  // 교육 요약 정보 조회
  const onGetEduSummary = async () => {
    setLoading(true);
    try {
      const params = {
        filter: JSON.stringify(eduSummary.param.filter),
      };
      await dispatch(getEduSummary(params));
      setLoading(false);
    } catch (error) {
      console.log('EduSidebar onGetEduSummary', error);
    }
  };

  // 교육 선택
  const onSelectEduSummary = (target: string) => {
    history.push('/eduresult');
    if (target === 'all') {
      dispatch(changeSummaryState({ key: 'param', value: { filter: {} } }));
      dispatch(changeTargetState({ key: 'selectedList', value: [] }));
      setSearchMode(false);
      setSearchText('');
    }
    dispatch(changeSummaryState({ key: 'selectedEduNo', value: target }));
    const sortField = target === 'all' ? 'eduNo' : 'eduTargetNo';
    dispatch(
      changeTargetState({
        key: 'param',
        value: { filter: {}, sort: [{ field: sortField, order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
    dispatch(changeTargetState({ key: 'selectedList', value: [] }));
  };

  // 교육 수정
  const onEditEdu = async () => {
    try {
      if (openModalTarget.eduData) {
        const { eduNo, eduName, startEpoch, endEpoch, examInfo } = openModalTarget.eduData;
        const params: any = {
          eduNo: eduNo,
        };

        // 수정 여부
        let isEdit = false;

        // 교육명 수정 시
        if (values.eduName !== eduName) {
          isEdit = true;
          params.eduName = values.eduName;
        }

        // 시작일자 수정 시
        if (values.startEpoch.format('YYYY-MM-DD') !== timeFormatFromUTCEpoch(startEpoch, 3)) {
          isEdit = true;

          //  변경날짜가 당일인 경우 시간값도 추가 전송
          params.startEpoch =
            values.startEpoch.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
              ? epochFromDate(nowEpoch() * 1000)
              : epochFromDate(values.startEpoch);
        }

        // 종료일자 수정 시
        if (values.endEpoch.format('YYYY-MM-DD') !== timeFormatFromUTCEpoch(endEpoch, 3)) {
          isEdit = true;
          params.endEpoch = epochFromDate(values.endEpoch);
        }

        // 연결훈련 수정 시
        const examNoList: Array<number> = [];
        examInfo?.forEach((info: any) => {
          examNoList.push(info.examNo);
        });
        if (JSON.stringify(values.examNoArray) !== JSON.stringify(examNoList)) {
          isEdit = true;
          params.examNoArray = JSON.stringify(values.examNoArray);
        }

        if (isEdit) {
          setModalLoading(true);
          const response: any = await dispatch(putEdu(params));

          if (!response.data.error) {
            closeModal();
          } else if (response.data.error[0].errInfo.includes('Duplicate')) {
            // 중복된 교육명
            setError('eduName', {
              type: 'duplicate',
              message: formatMessage({
                id: 'Edu_39',
                defaultMessage: '이미 사용중인 교육명입니다.',
              }),
            });
          } else {
            // 기타 에러
            setError('eduName', { type: 'error', message: response?.data?.error[0]?.errInfo });
          }
          setModalLoading(false);
        } else {
          closeModal();
        }
      }
    } catch (error) {
      console.log('EduSidebar onEditEdu', error);
    }
  };

  // 교육 삭제
  const onDeleteEdu = async () => {
    try {
      if (openModalTarget.eduData) {
        setModalLoading(true);
        const params = {
          serviceNo: userData.serviceNo,
          eduNoArray: JSON.stringify([openModalTarget.eduData.eduNo]),
        };

        const response: any = await dispatch(deleteEdu(params));
        if (!response.data.error) {
          setModalLoading(false);
          setOpenModalTarget({});
        }
      }
    } catch (error) {
      console.log('EduSidebar onDeleteEdu', error);
    }
  };

  // 훈련 조회
  const onGetExam = async () => {
    try {
      setModalLoading(true);
      await dispatch(getExam());
      setModalLoading(false);
    } catch (error) {
      console.log('StrartEdu onGetExam', error);
    }
  };

  // 필터 메뉴
  const filterMenu = useMemo(
    () => [
      {
        key: 'eduEnd',
        text: formatMessage({ id: 'Edu_144', defaultMessage: '완료된 교육' }),
      },
      {
        key: 'eduIng',
        text: formatMessage({ id: 'Edu_145', defaultMessage: '진행중인 교육' }),
      },
      {
        key: 'eduReservation',
        text: formatMessage({ id: 'Edu_146', defaultMessage: '예약된 교육' }),
      },
    ],
    [],
  );
  
  // 설정 메뉴 클릭 이벤트
  const onClickDropdownMenu = async (eduNo: any, target: string) => {
    // 선택한 교육 정보 저장
    setOpenModalTarget({ target: target, eduData: eduSummary.dataByEduNo[eduNo] });

    // datePicker값에 맞게 변환
    const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
    const start: any = timeFormatFromUTCEpoch(eduSummary.dataByEduNo[eduNo].startEpoch, 3);
    const end: any = timeFormatFromUTCEpoch(eduSummary.dataByEduNo[eduNo].endEpoch, 3);

    // 연결훈련 리스트
    const examNoList: Array<number> = [];
    eduSummary?.dataByEduNo[eduNo]?.examInfo?.forEach((info: any) => {
      examNoList.push(info.examNo);
    });

    // 선택한 교육 정보 state에 저장
    setValues({
      eduName: eduSummary.dataByEduNo[eduNo].eduName,
      startEpoch: moment(start, dateFormat),
      endEpoch: moment(end, dateFormat),
      examNoArray: examNoList,
    });

    if (target === 'eduEdit') {
      onGetExam();
    }
  };

  // 모달 닫기
  const closeModal = () => {
    clearErrors();
    setOpenModalTarget({});
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'startEpoch') {
      isDisable =
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') || current > moment('2037-12-30').startOf('day');
    } else {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(values.startEpoch).add(1, 'd') ||
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') ||
        current > moment('2037-12-31').add(1, 'd');
    }
    return isDisable;
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any) => {
    if (type === 'startEpoch') {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(values.endEpoch)) {
        setValues({
          ...values,
          startEpoch: moment(current).startOf('day'),
          endEpoch: null,
        });
        setValue('endEpoch', null);
      } else {
        setValues({ ...values, startEpoch: moment(current).startOf('day') });
      }
    } else if (type === 'endEpoch') {
      setValues({
        ...values,
        endEpoch: moment(current).hour(23).minute(59).second(59).millisecond(0),
      });
    }
  };

  // 드롭다운 리스트 선택 이벤트
  const handleSelectList = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: data,
    });
  };

  // 태그 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (name: any, exceptTagNo: any) => {
    const selectedValue = values[name].filter((tagNo: any) => tagNo !== exceptTagNo);
    setValues({
      ...values,
      [name]: selectedValue,
    });
  };

  // 연결훈련 드롭다운 메뉴
  const examMenu: any = [];
  if (examData.length > 0) {
    const remainData = examData?.filter((exam: any) => !values.examNoArray?.includes(exam.examNo));
    if (remainData.length > 0) {
      remainData.forEach((exam: any) => {
        examMenu.push(
          <Select.Option value={exam.examNo} key={exam.examName}>
            {exam.examName}
          </Select.Option>,
        );
      });
    }
  }

  // 연결훈련 태그
  const examTagRender = (props: any, name: any) => {
    let tagComponent = null;
    if (examData.length > 0) {
      const remainData = examData?.filter((exam: any) => exam.examNo === props.value)[0];
      if (remainData) {
        const { examNo, examName } = remainData;
        tagComponent = (
          <Tag closable className="default-tag" onClose={() => handleClearTag(name, examNo)}>
            {examName}
          </Tag>
        );
      }
    }
    return tagComponent;
  };

  // 훈련 요약 필터
  const handleSummaryFilter = async (type: string) => {
    const selectedFilter = eduSummary.param.filter || {};
    if (selectedFilter[type]) {
      delete selectedFilter[type];
    } else {
      selectedFilter[type] = 1;
    }

    await dispatch(changeSummaryState({ key: 'selectedEduNo', value: 'all' }));
    await dispatch(changeSummaryState({ key: 'param', value: { filter: selectedFilter } }));
    dispatch(changeTargetState({ key: 'selectedList', value: [] }));
    dispatch(
      changeTargetState({
        key: 'param',
        value: { filter: selectedFilter, sort: [{ field: 'eduNo', order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
  };

  // 필터 검색
  const handleSearch = async () => {
    setSearchMode(true);
    if (searchText) {
      eduSummary.param.filter.searchAll = searchText;
    } else {
      // 검색어 없을 시 searchAll 파라미터 제거
      delete eduSummary.param.filter.searchAll;
    }

    await dispatch(changeSummaryState({ key: 'selectedEduNo', value: 'all' }));
    await dispatch(changeSummaryState({ key: 'param', value: { filter: eduSummary.param.filter } }));
    dispatch(changeTargetState({ key: 'selectedList', value: [] }));
    dispatch(
      changeTargetState({
        key: 'param',
        value: { filter: eduSummary.param.filter, sort: [{ field: 'eduNo', order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
  };

  // 필터 검색 취소
  const handleCancelSearch = async () => {
    setSearchText('');
    delete eduSummary.param.filter.searchAll;
    await dispatch(changeSummaryState({ key: 'param', value: { filter: eduSummary.param.filter } }));
    dispatch(
      changeTargetState({
        key: 'param',
        value: { filter: eduSummary.param.filter, sort: [{ field: 'eduNo', order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
  };

  return (
    <div className="summary-list edu-summary">
      <Loading loading={loading} darkMode />
      {/* 전체 교육 */}
      <div className="summary-list-header">
        <div
          className={`summary-title-area summary-item ${eduSummary.selectedEduNo === 'all' ? 'selected' : ''
            }`}
          onClick={() => onSelectEduSummary('all')}
          aria-hidden="true"
        >
          {/* <img src={allImage} alt="all" /> */}
          <img src="/img/exam/exam_all.png" alt="all" />

          <span>{formatMessage({ id: 'Edu_19', defaultMessage: '전체 교육' })}</span>
          <button
            type="button"
            onClick={(e: any) => {
              e.stopPropagation();
              setSearchMode(!searchMode);
            }}
          >
            {/* <img
              src={icoSearchImage}
              alt="search"
              className={`search-icon ${searchMode ? 'active' : ''}`}
            /> */}
            <img
              src="/img/tag/ico_search.png"
              alt="search"
              className={`search-icon ${searchMode ? 'active' : ''}`}
            />
          </button>
        </div>
        <div className="summary-list-filter">
          
          <div className="filter-list">
            {/* 훈련 필터 */}
            {filterMenu.map((menu: any) => {
              return (
                <div
                  className={`summary-search-item ${
                    eduSummary.param.filter && eduSummary.param.filter[menu.key] ? 'active' : ''
                  }`}
                  key={menu.key}
                  onClick={() => handleSummaryFilter(menu.key)}
                  aria-hidden="true"
                >
                  <div className="filter-icon" />
                  <div className="filter-name">{menu.text}</div>
                </div>
              );
            })}
          </div>
          {/* 검색 */}
          {searchMode && (
            <div className="summary-search-item active">
              <div className="summary-search-text">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                  placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                />
                <div className="summary-search-btns">
                  {/* filter-search */}
                  {/* <img
                    className="search-btn"
                    // filter-btn
                    src={searchImage}
                    alt="search"
                    onClick={handleSearch}
                    aria-hidden="true"
                    title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                  /> */}
                  <img
                    className="search-btn"
                    // filter-btn
                    src="/img/exam/search.png"
                    alt="search"
                    onClick={handleSearch}
                    aria-hidden="true"
                    title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                  />
                  {/* <img
                    className="search-btn"
                    src={cancelImage}
                    alt="cancel"
                    onClick={handleCancelSearch}
                    aria-hidden="true"
                    title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                  /> */}
                  <img
                    className="search-btn"
                    src="/img/exam/search_cancel.png"
                    alt="cancel"
                    onClick={handleCancelSearch}
                    aria-hidden="true"
                    title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                  />
                </div>
              </div>
            </div>
          )}          
        </div>
      </div>
      {/* 교육 요약 목록 */}
      <div className={`summary-list-content ${searchMode ? 'extend' : ''}`}>
        {eduSummary.data?.length > 0 ? (
          eduSummary.data.map((data: any) => {
            return (
              <EduSidebarItem
                key={data.eduNo}
                summaryInfo={data}
                selectedItem={eduSummary.selectedEduNo}
                onClickItem={onSelectEduSummary}
                onClickDropdownMenu={onClickDropdownMenu}
              />
            );
          })
        ) : (
          <div className="no-exam-summary">
            {formatMessage({ id: 'Edu_21', defaultMessage: '교육이 없습니다.' })}
          </div>
        )}
      </div>

      {/* 교육 수정 모달 */}
      {openModalTarget.target === 'eduEdit' && (
        <ModalTemplate
          className="edu-edit-modal modal-464"
          visible={openModalTarget.target === 'eduEdit'}
          title={`${formatMessage({ id: 'Edu_52', defaultMessage: '교육 수정' })} (No.${openModalTarget.eduData.eduNo
            })`}
          onOk={handleSubmit(onEditEdu)}
          onCancel={closeModal}
          okText={formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={modalLoading}
          disabled={Object.keys(errors)?.length > 0}
        >
          <Loading loading={modalLoading} />

          <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
            {/* 교육명 */}
            <div className="content-item">
              <div className="input-title">
                {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}*
              </div>
              <FormTextField
                name="eduName"
                defaultValue={values.eduName}
                error={errors.eduName}
                arrowPosition="top"
                register={register}
                onChange={(e: any) => setValues({ ...values, eduName: e.target.value })}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    name: (value: any) => valid.name(value),
                  },
                }}
              />
            </div>

            {/* 시작일자/종료일자 */}
            <div className="content-item">
              <div className="date-picker-area">
                <div className="date-picker-item">
                  <div className="text-field-title">
                    {formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}*
                  </div>
                  <FormDatePicker
                    control={control}
                    name="startEpoch"
                    value={values.startEpoch}
                    error={errors.startEpoch}
                    handleOnChange={handleOnChangeDate}
                    handleDisableDate={handleDisableDate}
                    helperText={
                      moment() < values.startEpoch &&
                      formatMessage({ id: 'Edu_42', defaultMessage: '교육을 예약함' })
                    }
                    disabled={values.startEpoch <= moment().startOf('day')}
                  />
                </div>
                <div className="date-picker-item">
                  <div className="text-field-title">
                    {formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}*
                  </div>
                  <FormDatePicker
                    control={control}
                    name="endEpoch"
                    value={values.endEpoch}
                    error={errors.endEpoch}
                    validation={{
                      validate: {
                        required: (value: any) => {
                          return valid.required(value || values.endEpoch);
                        },
                      },
                    }}
                    handleOnChange={handleOnChangeDate}
                    handleDisableDate={handleDisableDate}
                    helperText={
                      values.endEpoch &&
                      `${formatMessage({
                        id: 'Period_4',
                        defaultMessage: '교육 기간',
                      })}: ${moment.duration(values.endEpoch.diff(values.startEpoch)).humanize()}`
                    }
                    arrowPosition="top"
                    disabled={moment(values.endEpoch) < moment().startOf('day')}
                  />
                </div>
              </div>
            </div>

            {/* 연결훈련 */}
            <div className="content-item">
              <div className="input-title">
                {formatMessage({ id: 'Edu_47', defaultMessage: '연결훈련' })}
              </div>
              <FormSelectField
                className={`multi-select ${examData?.length > 0 ? '' : 'disabled'}`}
                formClassName="edu-edit"
                name="examNoArray"
                control={control}
                error={errors.examNoArray}
                option={examMenu}
                handleOnChange={handleSelectList}
                mode="multiple"
                showArrow
                placeholder={formatMessage({
                  id: 'Edu_45',
                  defaultMessage: '교육과 연결시킬 훈련을 선택하세요.',
                })}
                value={values.examNoArray}
                tagRender={(props: any) => examTagRender(props, 'examNoArray')}
                filterOption={(input: any, option: any) => {
                  return option.children.toLowerCase().includes(input.toLowerCase());
                }}
              />
            </div>
          </form>
        </ModalTemplate>
      )}

      {/* 교육 삭제 모달 */}
      {openModalTarget.target === 'eduDelete' && (
        <ModalTemplate
          className="modal-464"
          visible={openModalTarget.target === 'eduDelete'}
          title={formatMessage({ id: 'Edu_49', defaultMessage: '교육 삭제' })}
          onOk={onDeleteEdu}
          onCancel={closeModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Edu_50',
                defaultMessage: '삭제한 교육 정보는 복구할 수 없습니다.',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Edu_51',
                defaultMessage: '다음 정보를 삭제합니까?',
              })}
            </div>
          </div>
          <div className="modal-border-box">
            <>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">No</div>
                </li>
                <div>{openModalTarget.eduData?.eduNo}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Name_13', defaultMessage: '대상자명' })}
                  </div>
                </li>
                <div>{openModalTarget.eduData?.eduName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
                  </div>
                </li>
                <div>{`${timeFormatFromUTCEpoch(
                  openModalTarget.eduData?.startEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(openModalTarget.eduData?.endEpoch, 3)}`}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                  </div>
                </li>
                <div>{timeFormatFromUTCEpoch(openModalTarget.eduData?.regEpoch)}</div>
              </ul>
            </>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default EduSidebar;
