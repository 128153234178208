import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Checkbox, Popover, Radio, RadioChangeEvent } from 'antd';
import {
  CloseSquareOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { addEduTemplate, attachNameCheck, editEduTemplate } from 'store/edu';
import * as valid from 'utils/validation';
import { Option } from 'utils/commonValues';
import ModalTemplate from 'components/common/ModalTemplate';
import Loading from 'components/common/Loading';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import FormButtonGroup from 'components/common/FormButtonGroup';
import './EduTemplate.scss';

function EduTemplateAddEdit({
  visible,
  toggleModal,
  isAdmin,
  isAddMode,
  dataInfo,
  templateType = 'user',
}: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isJapan } = Option;
  // 썸네일 파일
  // const [thumbnail, setThumbnail]: any = useState(
  //   isAddMode || !dataInfo.thumbNail
  //     ? null
  //     : { name: dataInfo.thumbNail?.split('/')?.pop() }
  // );
  // pdf 파일
  const [pdfFile, setPdfFile]: any = useState(
    isAddMode
      ? { file: null, fileName: '' }
      : { file: null, fileName: dataInfo.fileInfo?.[0]?.fileName },
  );
  // 템플릿/퀴즈 현재 페이지
  const [page, setPage]: any = useState(1);
  // 객관식의 보기 리스트
  const [choiceList, setChoiceList]: any = useState([{ 1: '' }, { 2: '' }]);
  // 퀴즈 목록에 출력할 배열
  const [quizList, setQuizList]: any = useState(
    isAddMode ? [] : dataInfo.quizInfo?.filter((item: any) => !!item.quizNo),
  );
  // 퀴즈 변경 정보 담을 배열(파라미터)
  const [quizParamList, setQuizParamList]: any = useState([]);
  // file 타입 에러
  const [errorFile, setErrorFile]: any = useState({});
  // 선택한 퀴즈 정보
  const [selectedQuiz, setSelectedQuiz]: any = useState(null);
  // 컨텐츠명 변경 여부
  const [attachNameChanged, setAttachNameChanged] = useState(false);
  // 퀴즈 개수 제한 메시지
  const [quizCountError, setQuizCountError]: any = useState(null);
  // 퀴즈 삽입 체크박스 해제 시 메시지
  const [cancelCheckMessage, setCancelCheckMessage]: any = useState('');
  // 저장 안내 메시지
  const [saveError, setSaveError]: any = useState([]);

  const userData: any = useSelector((state: any) => {
    return {
      serviceNo: state.user.user?.serviceNo,
    };
  });
  const {
    errors,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  }: any = useForm({
    mode: 'all',
    defaultValues: {
      attachName: dataInfo.attachName || '',
      contentType: dataInfo.contentType || 4,
      fileName: dataInfo.fileName || '',
      filePath: (dataInfo.contentType !== 3 && dataInfo.fileInfo?.[0]?.filePath) || '',
      insertQuiz: dataInfo.quizInfo?.[0].quizNo ? true : false,
      serviceNo: dataInfo.serviceNo || '',
      isPublic: dataInfo.isPublic?.toString() || '1',
    },
  });

  const {
    errors: quizErrors,
    control: quizControl,
    register: quizRegister,
    handleSubmit: quizHandleSubmit,
    watch: quizWatch,
    setValue: quizSetValue,
    getValues: quizGetValues,
  }: any = useForm({
    mode: 'all',
    defaultValues: {
      quizType: 1,
      // quizContent: '',
      // quizSolution: '',
      // quizExplain: '',
    },
  });

  // 퀴즈 내용 변경 여부 확인
  const changeQuizzes = () => {
    const { quizType, quizContent, quizExplain, quizSolution } = quizWatch();
    let changed = false;

    if (!selectedQuiz && (quizContent || quizExplain || quizSolution)) {
      // 등록 - 입력된 내용 하나라도 있으면 change
      changed = true;
    } else if (selectedQuiz) {
      // 수정 - 선택한 퀴즈와 새로 입력한 퀴즈의 내용이 다르면 change
      const selectQuiz: any = {
        quizType: selectedQuiz.quizType,
        quizContent: selectedQuiz.quizContent,
        quizExplain: selectedQuiz.quizExplain,
        quizSolution: selectedQuiz.quizSolution,
        ...(selectedQuiz.quizType === 2 ? { extraInfo: selectedQuiz.extraInfo } : ''),
      };
      const watchQuiz: any = {
        quizType: quizType,
        quizContent: quizContent,
        quizExplain: quizExplain,
        quizSolution: quizSolution || selectedQuiz.quizSolution,
        ...(selectedQuiz.quizType === 2 ? { extraInfo: choiceList } : ''),
      };

      Object.keys(selectQuiz).forEach((key: string) => {
        if (selectQuiz[key] !== watchQuiz[key]) {
          changed = true;
        }
      });
    }
    return changed;
  };

  // 교육 템플릿 등록, 수정
  const onSaveTemplate = async (file: any) => {
    const { attachName, contentType, serviceNo, isPublic } = watch();

    if (loading) return;

    if (changeQuizzes()) {
      setSaveError([
        formatMessage({ id: 'Edu_140', defaultMessage: '저장되지 않은 데이터가 존재합니다.' }),
        formatMessage({
          id: 'Edu_141',
          defaultMessage: '작성한 퀴즈 내용을 저장한 후 다시 시도해주세요.',
        }),
      ]);
      return;
    }

    try {
      setLoading(true);

      const form = new FormData();
      if (watch().insertQuiz && quizParamList.length > 0) {
        // 체크 시 변경된 퀴즈 정보 보냄
        const quizParams = quizParamList?.map((quiz: any) => {
          const { quizNo, quizType, quizContent, quizSolution, quizExplain, extraInfo } = quiz;
          let result: any = {};
          result = {
            quizType: quizType,
            quizContent: quizContent,
            quizSolution: quizSolution,
            quizExplain: quizExplain,
          };
          if (quizNo) {
            result.quizNo = quizNo;
          }
          if (quizType === 2 && extraInfo) {
            result.extraInfo = JSON.stringify(extraInfo);
          }
          return result;
        });
        form.append('quizInfo', JSON.stringify(quizParams));
      } else if (!watch().insertQuiz && dataInfo.quizCount > 0) {
        // 체크 해제 시 기존 퀴즈 삭제
        const deleteTargets = dataInfo.quizInfo?.map((item: any) => {
          return { quizNo: item.quizNo };
        });
        form.append('quizInfo', JSON.stringify(deleteTargets));
      }
      if (!isAddMode) {
        form.append('eduAttachNo', dataInfo.eduAttachNo);
      }

      form.append('attachName', attachName);
      // pdf 타입이 아니면 파일 정보 보냄.
      if (contentType !== 3) {
        form.append('fileInfo', JSON.stringify(Object.keys(file).length > 0 ? [file] : []));
      }
      form.append('contentType', contentType);

      // 관리자 -> 기본 템플릿 등록: '0'
      // 관리자 -> 사용자 템플릿 등록: serviceNo
      // 사용자 -> 사용자 템플릿 등록: userData.serviceNo
      let paramServiceNo = '0';
      if (templateType === 'user') {
        paramServiceNo = isAdmin ? serviceNo : userData.serviceNo;
      }
      form.append('serviceNo', paramServiceNo);
      form.append('isBasic', isAdmin && templateType === 'basic' ? '1' : '0');
      form.append('isPublic', isAdmin && templateType === 'basic' ? isPublic.toString() : '1');

      // if (thumbnail) {
      //   form.append('thumbnail', thumbnail);
      // }

      if (pdfFile?.file) {
        form.append('pdf', pdfFile.file);
      }

      const response: any = await dispatch(
        isAddMode ? addEduTemplate(form) : editEduTemplate(form),
      );
      if (!response?.data?.error && !response?.data?.list?.[0]?.quizInfo?.list?.errorCount) {
        toggleModal(false);
        setLoading(false);
      } else if (response.data?.error?.[0]?.errInfo?.includes('Duplication')) {
        setLoading(false);
        setError('attachName', {
          type: 'duplication',
          message: formatMessage({ id: 'Vali_63', defaultMessage: '이미 등록된 컨텐츠명입니다.' }),
        });
      } else if (response.data?.error?.[0]?.errInfo?.includes('not PDF')) {
        setLoading(false);
        setErrorFile({
          pdfFile: formatMessage({
            id: 'Vali_62',
            defaultMessage: '파일 양식이 올바르지 않습니다.',
          }),
        });
      } else if (response.data?.error?.[0]?.errInfo?.includes('Invalid path')) {
        setLoading(false);
        setError('filePath', {
          type: 'path',
          message: formatMessage({ id: 'Edu_69', defaultMessage: '올바르지 않은 URL 입니다.' }),
        });
      } else if (response.data?.error?.[0]?.errInfo) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // 영상 추가 시 랜덤으로 키 부여
  const makeRandomKey = () => {
    const randomKey = Math.floor(Math.random() * 100000);
    return randomKey;
  };

  // 썸네일 파일 선택
  // const selectThumbnail = (e: any) => {
  //   const file = e.target.files[0];
  //   const fileTypeArray = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
  //   if (file.type && fileTypeArray.indexOf(file.type) > -1) {
  //     setErrorFile({ thumbnail: '' });
  //     setThumbnail(file);
  //   } else {
  //     setThumbnail(file);
  //     setErrorFile({
  //       thumbnail: formatMessage({
  //         id: 'Vali_62',
  //         defaultMessage: '파일 양식이 올바르지 않습니다.',
  //       }),
  //     });
  //   }
  // };

  // PDF 파일 선택
  const selectPDF = (e: any) => {
    const file = e.target.files[0];
    const fileTypeArray = ['application/pdf'];

    if (file.type && fileTypeArray.indexOf(file.type) > -1) {
      setErrorFile({ pdfFile: '' });
      setPdfFile({ file: file, fileName: file.name });
    } else {
      setPdfFile({ file: file, fileName: file.name });
      setErrorFile({
        pdfFile: formatMessage({ id: 'Vali_62', defaultMessage: '파일 양식이 올바르지 않습니다.' }),
      });
    }
  };

  // 객관식 보기 항목 추가
  const addChoice = () => {
    const list: any = [...choiceList];
    list.push({ [choiceList.length + 1]: '' });
    setChoiceList(list);
  };

  // 객관식 보기 항목 삭제
  const deleteChoice = () => {
    const list: any = [...choiceList];
    list.pop();
    const choiceDisappear = parseInt(quizWatch().quizSolution, 10) > list.length;
    if (!quizWatch().quizSolution || (quizWatch().quizSolution && choiceDisappear)) {
      quizSetValue('quizSolution', list.length.toString());
    }
    setChoiceList(list);
  };

  // 퀴즈 추가
  const addQuiz = () => {
    const { quizType, quizContent, quizExplain, quizSolution } = quizWatch();
    // 퀴즈 최대 50개 등록 제한
    if (quizList.length >= 50) {
      setQuizCountError(
        formatMessage(
          { id: 'Edu_129', defaultMessage: '퀴즈는 최대 {count}개까지 등록 가능합니다.' },
          { count: 50 },
        ),
      );
      return;
    }

    let quizInfo: any = {
      quizKey: makeRandomKey(),
      quizType: quizType,
      quizContent: quizContent,
      quizExplain: quizExplain,
      quizSolution: quizSolution,
    };

    if (quizType === 2) {
      quizInfo = { ...quizInfo, extraInfo: choiceList };
    }
    const tempList = [];
    tempList.push(quizInfo);
    setQuizList([...quizList, ...tempList]);
    setQuizParamList([...quizParamList, ...tempList]);
    cancelQuiz();
  };

  // 퀴즈 수정
  const editQuiz = () => {
    const { quizType, quizContent, quizExplain, quizSolution } = quizWatch();

    const quizInfo: any = {
      quizType: quizType,
      quizContent: quizContent,
      quizExplain: quizExplain,
      quizSolution: quizSolution || selectedQuiz.quizSolution,
      extraInfo: choiceList,
    };

    const key = selectedQuiz.quizNo ? 'quizNo' : 'quizKey';
    quizInfo[key] = selectedQuiz[key];
    const newQuizList = quizList.map((item: any) => {
      return item[key] === selectedQuiz[key] ? quizInfo : item;
    });
    setQuizList(newQuizList);

    let paramList = [...quizParamList];
    if (selectedQuiz.quizNo) {
      paramList.push(quizInfo);
    } else {
      paramList = quizParamList.map((item: any) => {
        return item.quizKey === selectedQuiz.quizKey ? quizInfo : item;
      });
    }
    setQuizParamList(paramList);
    cancelQuiz();
  };

  // 퀴즈 수정 취소
  const cancelQuiz = () => {
    setSelectedQuiz(null);
    quizSetValue('quizType', 1);
    quizSetValue('quizContent', '');
    quizSetValue('quizSolution', '');
    quizSetValue('quizExplain', '');
    setChoiceList([{ 1: '' }, { 2: '' }]);
    setSaveError([]);
  };

  // 퀴즈 목록에서 선택
  const selectQuiz = (quiz: any) => {
    const { quizContent, quizExplain, quizSolution, quizType } = quiz;
    quizSetValue('quizType', quizType);
    quizSetValue('quizContent', quizContent);
    quizSetValue('quizSolution', quizSolution);
    quizSetValue('quizExplain', quizExplain);

    if (quiz?.extraInfo?.length) {
      setChoiceList(quiz.extraInfo);
      quiz.extraInfo.forEach((choice: any) => {
        quizSetValue(
          Object.keys(choice)[0],
          Object.keys(choice)[0] ? choice[Object.keys(choice)[0]] : '',
        );
      });
    }
    setSaveError([]);
    setSelectedQuiz(quiz);
  };

  // 퀴즈 목록에서 삭제
  const deleteQuiz = (quiz: any) => {
    // 선택 정보 초기화
    cancelQuiz();
    setQuizCountError(null);
    // 기존 목록 삭제 (데이터베이스에 있음)
    if (quiz.quizNo) {
      setQuizParamList([...quizParamList, { quizNo: quiz.quizNo }]);
      setQuizList(quizList.filter((item: any) => item.quizNo !== quiz.quizNo));
    } else {
      // 새로 추가한 목록 삭제 (데이터베이스에 없음)
      setQuizParamList(quizParamList.filter((item: any) => item.quizKey !== quiz.quizKey));
      setQuizList(quizList.filter((item: any) => item.quizKey !== quiz.quizKey));
    }
  };

  // 객관식 보기 항목 onChange 이벤트
  const changeChoiceText = (e: any, key: string) => {
    const tempList = [...choiceList];
    tempList.forEach((item, index): any => {
      if (Object.keys(item)[0] === key) {
        tempList[index][key] = e.target.value;
      }
    });
    setChoiceList(tempList);
  };

  // 퀴즈 유형별 정답
  const quizChoiceList: any = [
    {
      key: 1,
      value: 1,
      text: formatMessage({ id: 'Edu_133', defaultMessage: 'O/X' }),
      solution: (
        <FormSelectField
          name="quizSolution"
          control={quizControl}
          error={quizErrors.quizSolution}
          menuList={[
            { key: 1, value: '1', text: 'O' },
            { key: 2, value: '2', text: 'X' },
          ]}
          value={quizWatch().quizSolution || selectedQuiz?.quizSolution}
          handleOnChange={(name: any, data: any) => quizSetValue(name, data)}
          validation={{ validate: { required: (value: any) => valid.required(value) } }}
          showArrow
        />
      ),
    },
    {
      key: 2,
      value: 2,
      text: formatMessage({ id: 'Edu_94', defaultMessage: '객관식' }),
      solution: (
        <div className="choice-box">
          <div className="choice-button-box">
            <button
              className={`choice-btn ${choiceList.length === 5 ? 'disabled' : ''}`}
              type="button"
              onClick={addChoice}
            >
              {formatMessage({ id: 'Edu_96', defaultMessage: '보기 추가' })}
            </button>
            <button
              className={`choice-btn ${choiceList.length <= 2 ? 'disabled' : ''}`}
              type="button"
              onClick={deleteChoice}
            >
              {formatMessage({ id: 'Edu_127', defaultMessage: '보기 삭제' })}
            </button>
          </div>
          <div className="choice-list">
            <Controller
              control={quizControl}
              name="quizSolution"
              rules={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              error={quizErrors.quizSolution}
              render={({ onChange }) => (
                <Radio.Group
                  className={quizErrors.quizSolution ? 'warning' : ''}
                  onChange={({ target: { value } }: RadioChangeEvent) => {
                    onChange(value);
                    quizSetValue('quizSolution', value);
                  }}
                  value={quizWatch().quizSolution || selectedQuiz?.quizSolution}
                >
                  {choiceList?.map((item: any) => {
                    const choiceKey = Object.keys(item)[0];
                    const choiceValue = Object.values(item)[0];
                    return (
                      <Radio value={choiceKey} key={choiceKey}>
                        <FormTextField
                          name={choiceKey}
                          register={quizRegister}
                          error={quizErrors[choiceKey]}
                          defaultValue={choiceValue}
                          onChange={(e: any) => changeChoiceText(e, choiceKey)}
                          placeholder={formatMessage({
                            id: 'Edu_97',
                            defaultMessage: '보기를 입력하세요.',
                          })}
                          validation={{
                            validate: {
                              required: (value: any) => valid.required(value),
                              range: (value: any) => valid.range(value, 0, 200),
                            },
                          }}
                        />
                      </Radio>
                    );
                  })}
                </Radio.Group>
              )}
            />
          </div>
        </div>
      ),
    },
    {
      key: 3,
      value: 3,
      text: formatMessage({ id: 'Edu_95', defaultMessage: '단답형' }),
      solution: (
        <FormTextField
          name="quizSolution"
          register={quizRegister}
          error={quizErrors.quizSolution}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              range: (value: any) => valid.range(value, 0, 30),
            },
          }}
          defaultValue={selectedQuiz?.quizSolution}
        />
      ),
      helpMessage: quizWatch().quizType === 3 && (
        <Popover
          overlayClassName="edu-template-popover"
          placement="right"
          content={
            <div>
              <div>
                ·
                {`${formatMessage({
                  id: 'Vali_43',
                  defaultMessage: '입력 허용 범위',
                })}: [~30${formatMessage({ id: 'Vali_44', defaultMessage: '자' })}]`}
              </div>
              <div>
                ·
                {formatMessage({
                  id: 'Edu_128',
                  defaultMessage:
                    '입력 정보가 완전히 일치하는 경우에만 정답이 되므로 입력에 주의해 주십시오.',
                })}
              </div>
            </div>
          }
        >
          <i className="question-icon">
            <QuestionCircleOutlined />
          </i>
        </Popover>
      ),
    },
  ];

  // 이전, 다음 이벤트
  const changePage = (type: string) => {
    if (type === 'next') {
      if (Object.keys(errors).length === 0) {
        submitPage1('next');
      }
    } else {
      submitPage2('prev');
    }
  };

  // MP4 유형
  const checkMP4 = (type: string, attachName: string, path: string) => {
    const mp4Regex = new RegExp(/(https?:\/\/)\S+?(.mp4)/);
    if (!path) {
      setLoading(false);
      setError('filePath', {
        type: 'error',
        message: formatMessage({ id: 'Vali_14', defaultMessage: '값이 없습니다.' }),
      });
    } else if (!mp4Regex.test(path)) {
      setLoading(false);
      setError('filePath', {
        type: 'error',
        message: formatMessage({ id: 'Edu_69', defaultMessage: '올바르지 않은 URL 입니다.' }),
      });
    } else {
      const video: any = document.createElement('video');
      video.src = path;
      video.addEventListener('error', () => {
        setLoading(false);
        setError('filePath', {
          type: 'error',
          message: formatMessage({ id: 'Edu_69', defaultMessage: '올바르지 않은 URL 입니다.' }),
        });
      });
      video.addEventListener('loadedmetadata', () => {
        let file = {};
        if (isAddMode) {
          file = {
            fileName: attachName,
            filePath: path,
            runningTime: parseInt(video.duration, 10),
          };
        } else if (dataInfo.fileInfo?.[0]?.filePath !== path) {
          file = { filePath: path, fileNo: dataInfo.fileInfo?.[0]?.fileNo };
        }

        if (watch().insertQuiz && type === 'next') {
          setLoading(false);
          setPage(2);
        } else {
          onSaveTemplate(file);
        }
      });
    }
  };

  // YouTube 유형
  const checkYouTube = (type: string, attachName: string, path: string) => {
    const youtubeRegex = new RegExp(/(https?:\/\/)\S+?((youtu.be)|(youtube.com))/);
    if (!path) {
      setLoading(false);
      setError('filePath', {
        type: 'error',
        message: formatMessage({ id: 'Vali_14', defaultMessage: '값이 없습니다.' }),
      });
    } else if (!youtubeRegex.test(path)) {
      setLoading(false);
      setError('filePath', {
        type: 'error',
        message: formatMessage({ id: 'Edu_69', defaultMessage: '올바르지 않은 URL 입니다.' }),
      });
    } else if (watch().insertQuiz && type === 'next') {
      setLoading(false);
      setPage(2);
    } else {
      let file = {};
      if (isAddMode) {
        file = {
          fileName: attachName,
          filePath: path,
          runningTime: 0,
        };
      } else if (dataInfo.fileInfo?.[0]?.filePath !== path) {
        file = { filePath: path, fileNo: dataInfo.fileInfo?.[0]?.fileNo };
      }
      onSaveTemplate(file);
    }
  };

  // PDF 유형
  const checkPDF = (type: string, fileObj: any) => {
    const { file, fileName } = fileObj;
    if (!fileName && !file) {
      setLoading(false);
      // 파일 미선택 시
      setErrorFile({ pdfFile: formatMessage({ id: 'Vali_14', defaultMessage: '값이 없습니다.' }) });
    } else if (file && file.type.indexOf('application/pdf') < 0) {
      setLoading(false);
      // PDF 형식이 아닐 시
      setErrorFile({
        pdfFile: formatMessage({ id: 'Vali_62', defaultMessage: '파일 양식이 올바르지 않습니다.' }),
      });
    } else if (!file && fileName) {
      // 등록된 PDF가 있는 상태에서 변경 사항 없을 시 (템플릿 수정)
      if (watch().insertQuiz && type === 'next') {
        setLoading(false);
        setPage(2);
      } else {
        onSaveTemplate(null);
      }
    } else {
      // PDF 파일이 새로 추가되거나 변경될 시
      setPdfFile({ file: file, fileName: file.name });
      if (watch().insertQuiz && type === 'next') {
        setLoading(false);
        setPage(2);
      } else {
        onSaveTemplate(null);
      }
    }
  };

  // 컨텐츠명 onchange 이벤트
  const onChangeAttachName = (e: any) => {
    if (e.target.value !== dataInfo.attachName) {
      setAttachNameChanged(true);
    }
  };

  // 컨텐츠명 중복 확인
  const checkAttachValid = async (type: string) => {
    const { attachName } = watch();
    const params = {
      serviceNo: userData.serviceNo,
      attachName: attachName,
    };
    const response: any = await dispatch(attachNameCheck(params));
    setAttachNameChanged(false);
    if (response.data?.error?.[0]?.errInfo?.includes('Duplicate')) {
      setError('attachName', {
        type: 'duplication',
        message: formatMessage({ id: 'Vali_63', defaultMessage: '이미 등록된 컨텐츠명입니다.' }),
      });
    } else {
      checkContentValid(type);
    }
  };

  // 컨텐츠타입별 입력 값 확인
  const checkContentValid = (type: string) => {
    const { attachName, contentType, filePath } = watch();
    setLoading(true);
    if (contentType === 1 || contentType === 4) {
      // mp4
      checkMP4(type, attachName, filePath);
    } else if (contentType === 2) {
      // youtube
      checkYouTube(type, attachName, filePath);
    } else if (contentType === 3) {
      // pdf
      checkPDF(type, pdfFile);
    }
  };

  // 1페이지 입력값 확인
  const submitPage1 = async (type: string) => {
    if (!watch().attachName) {
      setError('attachName', {
        type: 'error',
        message: formatMessage({ id: 'Vali_14', defaultMessage: '값이 없습니다.' }),
      });
    } else if (attachNameChanged) {
      // 컨텐츠명 변경 시
      checkAttachValid(type);
    } else {
      checkContentValid(type);
    }
  };

  // 2페이지 입력값 확인
  const submitPage2 = (type: string) => {
    if (type === 'prev') {
      setPage(1);
    } else {
      const { contentType, attachName, filePath } = watch();
      if (contentType === 1 || contentType === 4) {
        // mp4
        checkMP4(type, attachName, filePath);
      } else if (contentType === 2) {
        // youtube
        checkYouTube(type, attachName, filePath);
      } else if (contentType === 3) {
        // pdf
        checkPDF(type, pdfFile);
      }
    }
  };

  return (
    <ModalTemplate
      className="edu-template-add-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {isAddMode
              ? formatMessage({ id: 'Edu_53', defaultMessage: '교육 템플릿 등록' })
              : formatMessage({ id: 'Edu_83', defaultMessage: '교육 템플릿 수정' })}
          </div>
        </div>
      }
      onCancel={() => toggleModal(false)}
      footer={
        <div className="edu-edit-footer">
          {page === 2 ? (
            <button type="button" className="footer-btn prev" onClick={() => changePage('prev')}>
              <LeftOutlined />
              {formatMessage({ id: 'Button_31', defaultMessage: '이 전' })}
            </button>
          ) : (
            <button type="button" onClick={() => toggleModal(false)}>
              {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            </button>
          )}
          {page === 1 && watch().insertQuiz ? (
            <button type="button" className="footer-btn next" onClick={() => changePage('next')}>
              {formatMessage({ id: 'Button_7', defaultMessage: '다 음' })}
              <RightOutlined />
            </button>
          ) : (
            <button
              type="submit"
              className={`footer-btn ok ${
                (page === 2 && quizList.length < 1) || loading ? 'disabled' : ''
              }`}
              onClick={
                page === 2 ? () => submitPage2('') : handleSubmit((form: any) => submitPage1(''))
              }
            >
              {isAddMode
                ? formatMessage({ id: 'Button_10', defaultMessage: '등 록' })
                : formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
            </button>
          )}
          {saveError.length > 0 && (
            <div className="arrow-box top">
              <div>{saveError[0]}</div>
              <div>{saveError[1]}</div>
            </div>
          )}
        </div>
      }
      loading={loading}
    >
      <Loading loading={loading} />

      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        <div className={page === 1 ? '' : 'none'}>
          {/* 서비스No */}
          {templateType === 'user' && !!isAdmin && (
            <>
              <div className="input-title">
                {formatMessage({ id: 'Service_8', defaultMessage: '서비스No' })}*
              </div>
              <FormTextField
                name="serviceNo"
                error={errors.serviceNo}
                register={register}
                arrowPosition="top"
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    number: (value: any) => valid.number(value),
                  },
                }}
                disabled={!isAddMode}
              />
            </>
          )}

          {/* 컨텐츠명 */}
          <div
            className={`${
              templateType === 'user' && !!isAdmin ? 'text-field-title' : 'input-title'
            }`}
          >
            {formatMessage({ id: 'Edu_5', defaultMessage: '컨텐츠명' })}*
          </div>
          <FormTextField
            name="attachName"
            error={errors.attachName}
            register={register}
            arrowPosition="top"
            validation={{
              validate: {
                required: (value: any) => valid.required(value),
                name: (value: any) => valid.name(value),
              },
            }}
            onChange={onChangeAttachName}
          />
          {/* 공개여부 */}
          {!!isAdmin && templateType === 'basic' && (
            <>
              <div className="text-field-title">
                {formatMessage({ id: 'Template_35', defaultMessage: '공개 여부' })}
              </div>
              <FormButtonGroup
                className="edu-add-edit-button-group"
                control={control}
                name="isPublic"
                error={errors.isPublic}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value === 0 ? '0' : value),
                  },
                }}
                buttonList={[
                  {
                    visible: true,
                    text: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
                    value: '1',
                  },
                  {
                    visible: true,
                    text: formatMessage({ id: 'Template_39', defaultMessage: '비공개' }),
                    value: '0',
                  },
                ]}
                defaultValue={isAddMode ? '1' : watch().isPublic.toString()}
                handleOnChange={(value: any) => setValue('isPublic', value)}
              />
            </>
          )}

          {/* 컨텐츠 유형 */}
          <div className="edu-item">
            <div className="text-field-title">
              {formatMessage({ id: 'Edu_82', defaultMessage: '컨텐츠 유형' })}*
              <Popover
                overlayClassName="edu-template-popover"
                placement="right"
                content={
                  <div>
                    <div>
                      {`1. MP4: ${formatMessage({
                        id: 'Edu_85',
                        defaultMessage: 'MP4 형식의 영상 URL을 추가합니다.',
                      })}`}
                    </div>
                    <div>
                      {`2. YouTube: ${formatMessage({
                        id: 'Edu_86',
                        defaultMessage: 'YouTube 형식의 영상 URL을 추가합니다.',
                      })}`}
                    </div>
                    <div className="ml-10">
                      {formatMessage({
                        id: 'Edu_89',
                        defaultMessage: '영상의 자체 썸네일이 자동으로 등록됩니다.',
                      })}
                    </div>
                    <div className="caution-message">
                      {`※ ${formatMessage({
                        id: 'Edu_87',
                        defaultMessage:
                          '등록 후 컨텐츠 보기로 재생 가능 여부 확인 후, 교육 실시를 진행해주시기 바랍니다.',
                      })}`}
                    </div>
                  </div>
                }
              >
                <i className="question-icon">
                  <QuestionCircleOutlined />
                </i>
              </Popover>
            </div>
            <FormSelectField
              name="contentType"
              control={control}
              error={errors.contentType}
              menuList={[
                // { key: 1, value: 1, text: 'MP4 (multiple)' },
                {
                  key: 2,
                  value: 2,
                  text: 'YouTube',
                },
                {
                  key: 3,
                  value: 3,
                  text: 'PDF',
                },
                {
                  key: 4,
                  value: 4,
                  text: 'MP4 (single)',
                },
              ]}
              handleOnChange={(key: string, value: number) => {
                // 컨텐츠 유형 변경 시 path 에러 제거
                clearErrors('filePath');
                if (dataInfo.contentType !== 3 && value === 3) {
                  // pdf 파일 초기화
                  setPdfFile({ file: null, fileName: '' });
                } else if (dataInfo.contentType === 3) {
                  // filePath 초기화
                  setValue('filePath', '');
                }
              }}
              defaultValue={isAddMode ? 4 : dataInfo.contentType}
              showArrow
            />
          </div>
          {/* 영상 URL */}
          {watch().contentType !== 3 && (
            <>
              <div className="text-field-title">
                {formatMessage({ id: 'Edu_54', defaultMessage: '컨텐츠 URL' })}*
              </div>
              <FormTextField
                name="filePath"
                error={errors.filePath}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
              />
            </>
          )}

          {watch().contentType === 3 && (
            <div className="file-select-area">
              <div className="text-field-title">
                {formatMessage({ id: 'Edu_93', defaultMessage: '컨텐츠 파일' })}*
              </div>
              <div className={`file-input-area ${errorFile?.pdfFile ? 'error' : ''}`}>
                {pdfFile?.fileName ? (
                  <label>{pdfFile.fileName}</label>
                ) : (
                  <label className="upload-hint">
                    {formatMessage({
                      id: 'Phishing_13',
                      defaultMessage: '파일을 선택하세요.',
                    })}
                  </label>
                )}
                <input
                  accept=".pdf"
                  type="file"
                  onChange={(e: any) => selectPDF(e)}
                  onClick={(e: any) => (e.target.value = null)} // eslint-disable-line
                  required
                />
                {errorFile?.pdfFile && <div className="arrow-box top">{errorFile?.pdfFile}</div>}
              </div>
            </div>
          )}

          {/* {watch().contentType !== 2 && (
            <div className="file-select-area">
              <div className="text-field-title">
                {formatMessage({ id: 'Edu_70', defaultMessage: '썸네일' })}
              </div>
              <div className={`file-input-area ${errorFile?.thumbnail ? 'error' : ''}`}>
                {thumbnail ? (
                  <label>{thumbnail.name}</label>
                ) : (
                  <label className="upload-hint">
                    {formatMessage({
                      id: 'Phishing_13',
                      defaultMessage: '파일을 선택하세요.',
                    })}
                  </label>
                )}
                <input
                  accept="image/bmp, image/jpg, image/jpeg, image/png, image/gif"
                  type="file"
                  onChange={(e: any) => selectThumbnail(e)}
                  onClick={(e: any) => (e.target.value = null)} // eslint-disable-line
                />
                {errorFile?.thumbnail && (
                  <div className="arrow-box top">{errorFile?.thumbnail}</div>
                )}
              </div>
            </div>
          )} */}

          <div className="text-field-title">
            <Controller
              control={control}
              name="insertQuiz"
              render={({ onChange }) => (
                <Checkbox
                  className="modal-checkbox"
                  onChange={(e) => {
                    onChange(e.target.checked);
                    setValue('insertQuiz', e.target.checked);
                    if (quizList.length > 0 && !e.target.checked) {
                      setCancelCheckMessage(
                        formatMessage({
                          id: 'Edu_131',
                          defaultMessage:
                            '등록되어 있는 퀴즈가 있습니다. 체크를 해제하면 등록되어 있는 퀴즈설정이 해제됩니다.',
                        }),
                      );
                    } else {
                      setCancelCheckMessage('');
                    }
                  }}
                  checked={watch().insertQuiz}
                >
                  <div className="input-title">
                    {formatMessage({
                      id: 'Edu_46',
                      defaultMessage: '컨텐츠에 퀴즈 삽입',
                    })}
                  </div>
                </Checkbox>
              )}
            />
            {!!cancelCheckMessage && (
              <div className="error-message font-size-12">{cancelCheckMessage}</div>
            )}
          </div>
        </div>
      </form>

      <form
        autoComplete="off"
        onSubmit={(e: any) => e.preventDefault()}
        onChange={() => setSaveError([])}
      >
        {/* {page === 2 && ( */}
        <div className={page === 2 ? '' : 'none'}>
          <div className="video-add-area">
            {/* 퀴즈 유형 */}
            <div className="edu-item">
              <div className="input-title">
                {formatMessage({ id: 'Edu_90', defaultMessage: '퀴즈 유형' })}
                {isJapan ? '' : '*'}
              </div>
              <FormSelectField
                name="quizType"
                control={quizControl}
                error={quizErrors.quizType}
                menuList={[
                  {
                    key: 1,
                    value: 1,
                    text: formatMessage({ id: 'Edu_133', defaultMessage: 'O/X' }),
                  },
                  {
                    key: 2,
                    value: 2,
                    text: formatMessage({ id: 'Edu_94', defaultMessage: '객관식' }),
                  },
                  {
                    key: 3,
                    value: 3,
                    text: formatMessage({ id: 'Edu_95', defaultMessage: '단답형' }),
                  },
                ]}
                handleOnChange={(name: any, data: any) => setValue('quizType', data)}
                value={quizWatch().quizType}
                showArrow
              />
            </div>

            {/* 질문 */}
            <div className="text-field-title">
              {formatMessage({ id: 'Edu_91', defaultMessage: '질문' })}
              {isJapan ? '' : '*'}
            </div>
            <div className="releative-box">
              <Controller
                control={quizControl}
                name="quizContent"
                error={quizErrors.quizContent}
                rules={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    range: (value: any) => valid.range(value, 0, 200),
                  },
                }}
                render={({ onChange, onBlur, value }: any) => (
                  <textarea
                    className={`textarea-input${quizErrors.quizContent ? ' warning' : ''}`}
                    cols={100}
                    rows={2}
                    onChange={onChange}
                    value={quizWatch().quizContent}
                  />
                )}
              />
              {!!quizErrors.quizContent && (
                <div className="arrow-box top">{quizErrors.quizContent.message}</div>
              )}
            </div>

            {/* 정답 */}
            <div className="input-title mt-10">
              {formatMessage({ id: 'Edu_35', defaultMessage: '정답' })}*
              {quizChoiceList[quizWatch().quizType - 1 || 0]?.helpMessage || <></>}
            </div>
            <div>{quizChoiceList[quizWatch().quizType - 1 || 0]?.solution}</div>

            {/* 문제 해설 */}
            <div className="text-field-title">
              {formatMessage({ id: 'Edu_92', defaultMessage: '문제 해설' })}*
            </div>
            <div className="releative-box">
              <Controller
                control={quizControl}
                name="quizExplain"
                rules={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    range: (value: any) => valid.range(value, 0, 300),
                  },
                }}
                render={({ onChange, onBlur, value }: any) => (
                  <textarea
                    className={`textarea-input${quizErrors.quizExplain ? ' warning' : ''}`}
                    cols={100}
                    rows={2}
                    onChange={onChange}
                    value={quizWatch().quizExplain}
                    defaultValue={quizWatch().quizExplain}
                  />
                )}
              />
              {!!quizErrors.quizExplain && (
                <div className="arrow-box top">{quizErrors.quizExplain.message}</div>
              )}
            </div>

            {/* 영상 목록 추가/수정/취소 버튼 */}
            <div className="video-btn-box">
              {selectedQuiz ? (
                // 선택한 영상 있을 경우
                <>
                  <button className="video-btn" type="button" onClick={cancelQuiz}>
                    {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
                  </button>
                  <button className="video-btn" type="button" onClick={editQuiz}>
                    {formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
                  </button>
                </>
              ) : (
                // 선택한 영상 없을 경우
                <button className="video-btn" type="button" onClick={quizHandleSubmit(addQuiz)}>
                  {formatMessage({ id: 'Button_30', defaultMessage: '추 가' })}
                </button>
              )}
            </div>
          </div>

          <div className="text-field-title">
            {formatMessage({ id: 'Edu_56', defaultMessage: '퀴즈 목록' })}
          </div>
          <div className="video-list-box">
            <ul className="video-list">
              {quizList?.map((quiz: any) => {
                let solutionText = quiz.quizSolution;
                if (quiz.quizType === 1) {
                  solutionText = quiz.quizSolution === '1' ? 'O' : 'X';
                }
                return (
                  <li className="video-list-item" key={`${quiz.quizNo || quiz.quizKey}`}>
                    <div
                      className="delete-icon"
                      onClick={() => deleteQuiz(quiz)}
                      aria-hidden="true"
                    >
                      <CloseSquareOutlined />
                    </div>
                    <div className="list-info" onClick={() => selectQuiz(quiz)} aria-hidden="true">
                      <span className="video-title">{quiz.quizContent}</span>
                      <div className="video-url ellipsis" title={solutionText}>
                        {`${formatMessage({
                          id: 'Edu_35',
                          defaultMessage: '정답',
                        })}: ${solutionText}`}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {quizCountError && <div className="error-message">{quizCountError}</div>}
        </div>
      </form>
    </ModalTemplate>
  );
}

export default EduTemplateAddEdit;
