import { Input, Popover } from 'antd';
import FormSwitchField from 'components/common/FormSwitchField';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import SyncTest from 'components/common/config/SyncTest';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import './ConfigTemplate.scss';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

// import qmarkImg from 'img/exam/startExam/btn_qmark.png';

export default function ConfigTemplate(props: { [key: string]: any }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [saveStatus, setSaveStatus] = useState<any>({});
  const [editable, setEditable] = useState(false);
  const { order, isAdmin, lists, category, updateConfig, styleConfig } = props;
  let parameterData: any = null;
  if (isAdmin === 1) {
    parameterData = useSelector((state: any) => state.admin[category].parameter, shallowEqual);
  } else {
    parameterData = useSelector((state: any) => state.config[category].parameter, shallowEqual);
  }
  const { errors, register, getValues, watch, setValue, control } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (parameterData) {
      // form 기본값 설정
      for (let i = 0; i < order.length; i += 1) {
        const classChild = lists[order[i]];

        for (let j = 0; j < classChild.length; j += 1) {
          const { parameter } = classChild[j];
          const paramInfo = parameterData[parameter];

          if (paramInfo?.parameter?.includes('resultMailSendSellMngr')) {
            setValue(parameter, JSON.parse(paramInfo.value)?.mail);
          } else {
            setValue(parameter, paramInfo.value);
          }
        }
      }
    }
  }, [parameterData]);

  const configClass: any = [];
  const locale: string | null = localStorage.getItem('language');
  const validFunction: any = valid;
  const { TextArea } = Input;
  const excludeJapan = Option.isJapan === 1 ? ['userTargetMasking', 'concurrentLoginSession', 'longTermInactiveLock'] : [];

  const focusConfig = (config: string) => {
    setSaveStatus({ ...saveStatus, [config]: 0 });
  };

  const saveConfig = async (config: string, value?: any) => {
    if (!errors[config]) {
      const systemConfig = parameterData[config];
      let nowValue = value !== undefined ? value : getValues()[config];
      // 관리자, 사용자 접근 제한 IP는 대문자로 치환
      if (config === 'AccessAllowIp' || config === 'AdminAccessAllowIp') {
        nowValue = nowValue.toUpperCase();
      }

      if ((systemConfig.beforeValue || '') !== nowValue) {
        try {
          const params: { [key: string]: any } = {
            parameter: config,
            nowValue: typeof nowValue === 'object' ? JSON.stringify(nowValue) : nowValue,
            beforeValue:
              typeof systemConfig.beforeValue === 'object'
                ? JSON.stringify(systemConfig.beforeValue)
                : systemConfig.beforeValue,
          };
          if (isAdmin) {
            params.systemConfigNo = systemConfig.systemConfigNo;
          } else {
            params.serviceConfigNo = systemConfig.serviceConfigNo;
          }

          await dispatch(updateConfig(params));
          setSaveStatus({ ...saveStatus, [config]: 1 });
        } catch (e) {
          console.log(e);
          setSaveStatus({ ...saveStatus, [config]: 2 });
        }
      }
    }
  };

  for (let i = 0; i < order.length; i += 1) {
    const classTitle = order[i];
    const classChild = lists[order[i]];
    const configItem = [];

    for (let j = 0; j < classChild.length; j += 1) {
      const { parameter, parameterNotice } = classChild[j];
      const paramInfo = parameterData[parameter];
      const validation: { [key: string]: any } = {};

      if (paramInfo?.paramOptions?.fieldValidate) {
        paramInfo.paramOptions.fieldValidate.map((value: any) => {
          if (typeof validFunction[value] === 'function') {
            validation[value] = (text: any) => validFunction[value](text);
          }
          return true;
        });
      }

      let formField = null;
      if (paramInfo.paramType === 0 || excludeJapan.includes(parameter)) {
        // do nothing!
      } else if (paramInfo.paramType === 2) {
        formField = (
          <FormSwitchField
            className="config-input-switch"
            control={control}
            defaultValue={Number(paramInfo.beforeValue)}
            name={parameter}
            handleOnChange={saveConfig}
          />
        );
      } else if (paramInfo.paramType === 3) {
        formField = (
          <FormSelectField
            name={parameter}
            control={control}
            defaultValue={paramInfo.beforeValue}
            menuList={paramInfo.paramOptions.menuList}
            handleOnChange={saveConfig}
          />
        );
      } else if (paramInfo.paramType === 4) {
        formField = (
          <div>
            <TextArea
              name={parameter}
              rows={4}
              defaultValue={paramInfo.beforeValue}
              onBlur={({ target: { value: data } }) => saveConfig(parameter, data)}
            />
          </div>
          // <Input onBlur={} />
        );
      }
      // switch + text
      else if (paramInfo.paramType === 5) {
        formField = (
          <>
            {parameter === 'resultMailSendSellMngr' && (
              <>
                <div className="manager-info-edit">
                  <span className="detail-title">mail: </span>
                  <div className="detail-value">
                    <form
                      className="manager-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                    >
                      <FormTextField
                        name="resultMailSendSellMngr"
                        value={watch().resultMailSendSellMngr}
                        error={errors.resultMailSendSellMngr}
                        register={register}
                        validation={{
                          validate: {
                            email: (value: any) => valid.email(value),
                          },
                        }}
                        disabled={!editable}
                      />
                    </form>
                  </div>

                  <div className={`edit-btns ${errors[paramInfo.parameter] ? 'disabled' : ''}`}>
                    {editable ? (
                      <>
                        <div
                          className="edit-btn"
                          title={formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            saveConfig(parameter, {
                              mail: watch().resultMailSendSellMngr,
                              active: JSON.parse(paramInfo.value)?.active,
                            });
                            setEditable(false);
                          }}
                          aria-hidden="true"
                        >
                          <CheckOutlined />
                        </div>
                        <div
                          className="edit-btn"
                          title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                          onClick={(e: any) => {
                            saveResult = null;
                            e.stopPropagation();
                            setEditable(false);
                            setValue('resultMailSendSellMngr', JSON.parse(paramInfo.value)?.mail);
                            focusConfig(parameter);
                          }}
                          aria-hidden="true"
                        >
                          <CloseOutlined />
                        </div>
                      </>
                    ) : (
                      <div
                        className="edit-btn"
                        title={formatMessage({
                          id: 'Target_16',
                          defaultMessage: '편집하려면 클릭하세요.',
                        })}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setEditable(true);
                          focusConfig(parameter);
                        }}
                        aria-hidden="true"
                      >
                        <EditOutlined />
                      </div>
                    )}
                  </div>
                </div>
                <FormSwitchField
                  className={`config-input-switch ${
                    !JSON.parse(paramInfo.value)?.mail ? 'disabled' : ''
                  }`}
                  control={control}
                  defaultValue={JSON.parse(paramInfo.value)?.active === '1' ? 1 : 0}
                  name={parameter}
                  handleOnChange={(name: string, data: number) =>
                    saveConfig(parameter, {
                      mail: JSON.parse(paramInfo.value)?.mail,
                      active: data.toString(),
                    })
                  }
                />
              </>
            )}
          </>
        );
      } else {
        // 공백 입력 제한(처음과 끝에 공백 입력 불가, 연속 공백 입력 불가)
        validation.blank = (value: any) => valid.blank(value);

        // [GS 인증] 입력범위 제한
        if (Option.isGs === 1) {
          if (paramInfo.parameter === 'passwordChangePeriod') {
            validation.range = (value: any) => valid.range(value, 0, 180);
          } else if (paramInfo.parameter === 'systemUrl') {
            validation.range = (value: any) => valid.range(value, 0, 5000);
          } else if (paramInfo.parameter === 'mailSubjectPrefix') {
            validation.range = (value: any) => valid.range(value, 0, 5000);
          } else if (paramInfo.parameter === 'mailSenderEmail') {
            //
          } else if (paramInfo.parameter === 'passwordMinLength') {
            validation.minValue = (value: any) => valid.minValue(value, 8);
            validation.maxValue = (value: any) => valid.maxValue(value, 15);
          } else {
            validation.range = (value: any) => valid.range(value, 0, 50);
          }
          //
          if (paramInfo.parameter === 'mailId') {
            validation.mailId = (value: any) => valid.mailId(value);
          }
          if (paramInfo.parameter === 'mailPassword') {
            validation.mailPassword = (value: any) => valid.mailPassword(value);
          }
        } else if (paramInfo.parameter === 'passwordChangePeriod') {
          validation.maxValue = (value: any) => valid.maxValue(value, 180);
        } else if (paramInfo.parameter === 'systemUrl') {
          validation.range = (value: any) => valid.range(value, 0, 5000);
        } else if (paramInfo.parameter === 'mailSubjectPrefix') {
          validation.range = (value: any) => valid.range(value, 0, 5000);
        } else if (paramInfo.parameter === 'AccessAllowIp') {
          validation.range = (value: any) => valid.range(value, 0, 5000);
          validation.accessAllowIp = (value: any) => valid.accessAllowIp(value);
        } else if (paramInfo.parameter === 'AdminAccessAllowIp') {
          validation.range = (value: any) => valid.range(value, 0, 5000);
          validation.accessAllowIp = (value: any) => valid.accessAllowIp(value);
        } else if (paramInfo.parameter === 'nisDeclareCode') {
          validation.range = (value: any) => valid.range(value, 0, 100);
        } else if (paramInfo.parameter === 'ldapHost') {
          validation.range = (value: any) => valid.range(value, 0, 100);
        } else if (paramInfo.parameter === 'ldapDN') {
          validation.range = (value: any) => valid.range(value, 0, 100);
        } else if (paramInfo.parameter === 'ExamSystemUrl') {
          validation.rexamSystemUrlange = (value: any) => valid.examSystemUrl(value);
        } else if (paramInfo.parameter === 'userAccount') {
          validation.userAccount = (value: any) => valid.userAccount(value);
        } else if (paramInfo.parameter === 'mailSmtpPort') {
          validation.port = (value: any) => valid.port(value, 1, 65535);
        } else {
          validation.range = (value: any) => valid.range(value, 0, 50);
        }

        formField = (
          <FormTextField
            name={parameter}
            error={errors[paramInfo.parameter]}
            defaultValue={paramInfo.beforeValue}
            placeholder={Option.isJapan === 1 ? paramInfo.defaultValue : ''}
            register={register}
            validation={{ validate: validation }}
            onBlur={() => saveConfig(parameter)}
            onFocus={() => focusConfig(parameter)}
          />
        );
      }

      // 저장 메세지
      let saveResult = null;
      if (saveStatus[parameter] === 1) {
        if (parameter === 'userQuery' || parameter === 'deptQuery') {
          saveResult = null;
        } else {
          const successMsg = formatMessage({
            id: 'Config_11',
            defaultMessage: '저장되었습니다',
          });

          saveResult = (
            <div className="config-row-option-result">
              {`${successMsg} (${timeFormatFromUTCEpoch(new Date().getTime() / 1000)})`}
            </div>
          );
        }
      }

      // 팝업
      let configGuide = null;
      if (parameter === 'AccessAllowIp' || parameter === 'AdminAccessAllowIp') {
        configGuide = (
          <div>
            <div>
              {formatMessage({
                id: 'Config_9',
                defaultMessage: '입력 방식: { 사용자 No: [접근 IP] }',
              })}
            </div>
            <div>
              <div>{'ex>'}</div>
              <pre className="accessIpQmark-content">
                {`{
    "ALL": ["1.1.1.1"] ,
    "2": ["192.168.2.1~120"] ,
    "3": ["192.168.1.1~30" ,  "192.168.22~32.120"] ,
    ...
}`}
              </pre>
            </div>
          </div>
        );
      } else if (parameter === 'ExamSystemUrl') {
        configGuide = (
          <div>
            <div>
              {`${formatMessage({
                id: 'Config_8',
                defaultMessage: '입력 방식',
              })} : ["URL1", "URL2", ...]`}
            </div>
            <div>
              <div>{'ex>'}</div>
              <pre className="accessIpQmark-content">
                [&quot;https://mudfix.co.kr&quot;, &quot;https://mudfix.jp&quot;, ...]
              </pre>
            </div>
          </div>
        );
      } else if (parameter === 'userAccount') {
        configGuide = (
          <div>
            <div>
              {`${formatMessage({
                id: 'Config_8',
                defaultMessage: '입력 방식',
              })} : { ${formatMessage({
                id: 'ConfigSync_5',
                defaultMessage: '분류값: 서비스번호',
              })}, ... }`}
            </div>
            <div>
              <div>{'ex>'}</div>
              <pre className="accessIpQmark-content">{`{"1": 108, "2": 102, ...}`}</pre>
            </div>
          </div>
        );
      } else if (parameter === 'longTermInactiveLock' && Option.isJapan !== 1) {
        configGuide = (
          <div>
            <div>
              {formatMessage({
                id: 'Config_78',
                defaultMessage: '마지막 로그인 시간으로부터 입력한 일수가 지나면 계정이 잠깁니다.',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Config_79',
                defaultMessage: '일수를 지정하지 않으면 장기 미접속 시에도 계정이 잠기지 않습니다.',
              })}
            </div>
          </div>
        );
      }

      // smtp 예외
      const smtpDisable = parameterData?.mailHost?.beforeValue.includes('smtp.mudfix');

      // 특정 옵션 onoff에 따라 항목 예외
      if (
        paramInfo?.paramOptions?.onOff &&
        parameterData[paramInfo.paramOptions.onOff].beforeValue === '0'
      ) {
        configItem.push(<div />);
      } else if (
        paramInfo?.paramOptions?.offOn &&
        parameterData[paramInfo.paramOptions.offOn].beforeValue === '1'
      ) {
        configItem.push(<div />);
      } else if (smtpDisable && (parameter === 'mailId' || parameter === 'mailPassword')) {
        configItem.push(<div />);
      } else {
        let configText = '';
        // SMTP
        if (parameter === 'mailHost') {
          configText = formatMessage({ id: 'Config_16', defaultMessage: '호스트명' });
        } else if (parameter === 'mailSmtpPort') {
          configText = formatMessage({ id: 'Config_17', defaultMessage: 'SMTP 포트' });
        } else if (parameter === 'mailProtocol') {
          configText = 'Protocol';
        } else if (parameter === 'mailUseTls') {
          configText = formatMessage({ id: 'Config_18', defaultMessage: 'TLS Protocol 사용' });
        } else if (parameter === 'mailTimeLimit') {
          configText = formatMessage({ id: 'Config_19', defaultMessage: '타임아웃 (밀리 초)' });
        } else if (parameter === 'mailId') {
          configText = 'ID';
        } else if (parameter === 'mailPassword') {
          configText = 'Password';
        } else if (parameter === 'mailSmtpTested') {
          configText = '';
        } else if (parameter === 'mailSenderEmail') {
          // 관리자 전용
          configText = formatMessage({ id: 'Config_20', defaultMessage: '발송자' });
        } else if (parameter === 'mailSubjectPrefix') {
          // 관리자 전용
          configText = formatMessage({ id: 'Config_21', defaultMessage: '메일 머릿말(접두어)' });
        }
        // 기본 설정 (관리자에만 존재)
        else if (parameter === 'systemUrl') {
          configText = formatMessage({ id: 'Config_22', defaultMessage: '시스템 URL' });
        } else if (parameter === 'ExamSystemUrl') {
          configText = formatMessage({ id: 'Config_23', defaultMessage: '선택 가능한 훈련 URL' });
        } else if (parameter === 'sessionTimeout') {
          configText = formatMessage({ id: 'Config_24', defaultMessage: '세션 타임아웃(초)' });
        } else if (parameter === 'AdminAccessAllowIp') {
          configText = formatMessage({ id: 'Config_25', defaultMessage: '관리자 접근 허용 IP' });
        } else if (parameter === 'AccessAllowIp') {
          configText = formatMessage({ id: 'Config_26', defaultMessage: '사용자 접근 허용 IP' });
        } else if (parameter === 'userTargetMasking' && Option.isJapan !== 1) {
          configText = formatMessage({ id: 'Config_75', defaultMessage: '사용자 및 대상자 정보 마스킹' });
        } else if (parameter === 'requestUserDestMail') {
          configText = formatMessage({
            id: 'Config_74',
            defaultMessage: '사용자 등록 요청 수신 메일',
          });
        } else if (parameter === 'examMailSendRetry') {
          configText = formatMessage({
            id: 'Config_27',
            defaultMessage: '훈련 메일 실패시 재발송 횟수',
          });
        } else if (parameter === 'fileMaskingPercent') {
          configText = formatMessage({
            id: 'Config_28',
            defaultMessage: '감염 파일명 마스킹 비율 (%)',
          });
        } else if (parameter === 'examDefaultSendMail') {
          configText = formatMessage({ id: 'Config_73', defaultMessage: '고정 메일 주소' });
        } else if (parameter === 'allowLoginFailCount') {
          configText = formatMessage({
            id: 'Config_29',
            defaultMessage: '로그인 실패 제한 횟수 (5분간 잠김)',
          });
        } else if (parameter === 'concurrentLoginSession' && Option.isJapan !== 1) {
          configText = formatMessage({
            id: 'Config_76',
            defaultMessage: '계정 동시 접속 시 이전 세션 로그아웃',
          });
        } else if (parameter === 'longTermInactiveLock' && Option.isJapan !== 1) {
          configText = formatMessage({
            id: 'Config_77',
            defaultMessage: '장기 미접속 계정 잠금(일)',
          });
        } else if (parameter === 'includeAlphabet') {
          configText = formatMessage({ id: 'Config_30', defaultMessage: '영문자 포함' });
        } else if (parameter === 'includeNumeric') {
          configText = formatMessage({ id: 'Config_31', defaultMessage: '숫자 포함' });
        } else if (parameter === 'includeSpecialChar') {
          configText = formatMessage({ id: 'Config_32', defaultMessage: '특수문자 포함' });
        } else if (parameter === 'includeUpperCase') {
          configText = formatMessage({ id: 'Config_33', defaultMessage: '대문자 포함' });
        } else if (parameter === 'noUserId') {
          configText = formatMessage({ id: 'Config_34', defaultMessage: 'ID 포함 제한' });
        } else if (parameter === 'noSameCharCount') {
          configText = formatMessage({
            id: 'Config_35',
            defaultMessage: '동일 문자 연속 제한 (ex> aaa)',
          });
        } else if (parameter === 'noSequenceCharCount') {
          configText = formatMessage({
            id: 'Config_36',
            defaultMessage: '연속된 문자 사용 제한 (ex> abc)',
          });
        } else if (parameter === 'passwordMinLength') {
          configText = formatMessage({ id: 'Config_37', defaultMessage: '비밀번호 최소 길이' });
        } else if (parameter === 'passwordChangePeriod') {
          configText = formatMessage({ id: 'Config_38', defaultMessage: '비밀번호 변경 주기(일)' });
        } else if (parameter === 'passwordChangeNotifyPeriod') {
          configText = formatMessage({ id: 'Config_39', defaultMessage: '다음에 변경 주기(일)' });
        } else if (parameter === 'ldapHost') {
          configText = 'URL';
        } else if (parameter === 'ldapPort') {
          configText = 'Port';
        } else if (parameter === 'ldapDN') {
          configText = 'BindString';
        } else if (parameter === 'ldapPassword') {
          configText = 'Password';
        } else if (parameter === 'ldapServiceNo') {
          configText = 'ServiceNo';
        } else if (parameter === 'noticeTitle') {
          configText = formatMessage({ id: 'Config_40', defaultMessage: '공지 제목' });
        } else if (parameter === 'noticeContent') {
          configText = formatMessage({ id: 'Config_41', defaultMessage: '공지 내용' });
        }
        // 대상자 연동 (관리자에만 존재)
        else if (parameter === 'isSync') {
          configText = formatMessage({
            id: 'Config_42',
            defaultMessage: '대상자 연동 사용 (연동 시간: 04시 00분)',
          });
        } else if (parameter === 'syncType') {
          configText = formatMessage({ id: 'Config_43', defaultMessage: '연동 유형' });
        } else if (parameter === 'selectDatabase') {
          configText = 'DATABASE';
        } else if (parameter === 'isMultiService') {
          configText = formatMessage({ id: 'Config_44', defaultMessage: '여러 서비스에 연동' });
        } else if (parameter === 'syncServiceNo') {
          configText = formatMessage({ id: 'Service_8', defaultMessage: '서비스No' });
        } else if (parameter === 'syncUrl') {
          configText = 'Host';
        } else if (parameter === 'syncPort') {
          configText = 'Port';
        } else if (parameter === 'syncDatabase') {
          configText = 'DB';
        } else if (parameter === 'syncUser') {
          configText = 'User';
        } else if (parameter === 'syncPasswd') {
          configText = 'Password';
        } else if (parameter === 'userQuery') {
          configText = formatMessage({ id: 'Config_45', defaultMessage: '유저 쿼리' });
        } else if (parameter === 'userId') {
          configText = formatMessage({ id: 'Config_46', defaultMessage: '유저 아이디' });
        } else if (parameter === 'userName') {
          configText = formatMessage({ id: 'Config_47', defaultMessage: '유저 이름' });
        } else if (parameter === 'userEmail') {
          configText = formatMessage({ id: 'Config_48', defaultMessage: '유저 이메일' });
        } else if (parameter === 'userDeptId') {
          configText = formatMessage({ id: 'Config_49', defaultMessage: '부서 아이디' });
        } else if (parameter === 'userPosition') {
          configText = formatMessage({ id: 'Config_50', defaultMessage: '유저 직급' });
        } else if (parameter === 'tagPosition') {
          configText = formatMessage({ id: 'Config_51', defaultMessage: '직급 태그로 설정' });
        } else if (parameter === 'deptQuery') {
          configText = formatMessage({ id: 'Config_52', defaultMessage: '부서 쿼리' });
        } else if (parameter === 'deptId') {
          configText = formatMessage({ id: 'Config_53', defaultMessage: '부서 아이디' });
        } else if (parameter === 'deptName') {
          configText = formatMessage({ id: 'Config_54', defaultMessage: '부서 이름' });
        } else if (parameter === 'deptParentId') {
          configText = formatMessage({ id: 'Config_55', defaultMessage: '상위 부서 아이디' });
        } else if (parameter === 'userType') {
          configText = formatMessage({ id: 'Config_70', defaultMessage: '분류 아이디' });
        } else if (parameter === 'userAccount') {
          configText = formatMessage({ id: 'Config_71', defaultMessage: '서비스 분류' });
        }
        // 교육 설정
        else if (parameter === 'eduTitle') {
          configText = formatMessage({
            id: 'Config_56',
            defaultMessage: '대상자 교육 로그인 페이지 타이틀',
          });
        } else if (parameter === 'eduMailSubject') {
          configText = formatMessage({ id: 'Config_57', defaultMessage: '교육 메일 제목' });
        } else if (parameter === 'eduSenderName') {
          configText = formatMessage({ id: 'Config_58', defaultMessage: '교육 메일 발신자명' });
        } else if (parameter === 'eduSenderEmail') {
          configText = formatMessage({
            id: 'Config_59',
            defaultMessage: '교육 메일 발신자 이메일',
          });
        }
        // 신고 설정
        else if (parameter === 'declareEnable') {
          configText = formatMessage({ id: 'Config_60', defaultMessage: '신고하기 사용' });
        } else if (parameter === 'declareType') {
          configText = formatMessage({ id: 'Config_61', defaultMessage: '신고하기 배너 사용' });
        } else if (parameter === 'endExamDeclare') {
          configText = formatMessage({ id: 'Config_62', defaultMessage: '훈련 종료 후 신고가능' });
        } else if (parameter === 'nisDeclareEnable') {
          configText = formatMessage({ id: 'Config_63', defaultMessage: '국정원 신고하기 사용' });
        } else if (parameter === 'nisDeclareCode') {
          configText = formatMessage({
            id: 'Config_64',
            defaultMessage: '기관코드 (.jpg는 제외하고 기관코드만 입력해주세요.)',
          });
        } else if (parameter === 'declareTitle') {
          configText = formatMessage({ id: 'Config_65', defaultMessage: '신고 페이지 타이틀' });
        } else if (parameter === 'includeEmail') {
          configText = formatMessage({ id: 'Config_66', defaultMessage: '이메일 항목 포함' });
        } else if (parameter === 'includeDivision') {
          configText = formatMessage({ id: 'Config_67', defaultMessage: '소속 항목 포함' });
        } else if (parameter === 'includeSubject') {
          configText = formatMessage({ id: 'Config_68', defaultMessage: '신고 제목 항목 포함' });
        } else if (parameter === 'includeContent') {
          configText = formatMessage({ id: 'Config_69', defaultMessage: '신고 내용 항목 포함' });
        }
        // 메일 설정
        else if (parameter === 'resultMailSend') {
          configText = formatMessage({ id: 'Name_6', defaultMessage: '관리자' });
        } else if (parameter === 'resultMailSendTarget') {
          configText = formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' });
        } else if (parameter === 'resultMailSendSellMngr') {
          configText = formatMessage({ id: 'Config_72', defaultMessage: '대리점' });
        }

        if (
          // 특정 옵션 saxa일 때만 보이도록 설정
          (parameter !== 'examDefaultSendMail' && parameter !== 'requestUserDestMail') ||
          (Option.customer === 'saxa' &&
            (parameter === 'examDefaultSendMail' || parameter === 'requestUserDestMail'))
        ) {
          configItem.push(
            <div className="input-gap">
              <div className={`config-row-option ${parameter}`}>
                <div className="config-row-option-title">
                  <div className="config-class-title-wrap config-row-option-text">
                    {/* {locale && parameterNotice[locale]} */}
                    {configText}
                  </div>
                  {configGuide && (
                    <Popover placement="right" content={configGuide}>
                      <div className="accessIpQmark">
                        <img src="/img/exam/startExam/btn_qmark.png" alt="" />
                      </div>
                    </Popover>
                  )}
                </div>
                <div className={`config-row-option-value ${paramInfo.paramType === 4 && 'right'}`}>
                  <form>{formField}</form>
                  {(parameter === 'userQuery' || parameter === 'deptQuery') && (
                    <SyncTest parameter={parameter} />
                  )}
                </div>
              </div>
              {saveResult}
            </div>,
          );
        }
      }
    }

    // 클래스
    configClass.push(
      <div className="config-class">
        <div className="config-class-top">
          <div
            className={`config-class-title-wrap config-class-title ${
              styleConfig?.length < 1 ? 'none' : styleConfig?.join(' ')
            }`}
          >
            {classTitle}
          </div>
        </div>
        <div className="config-class-box">{configItem}</div>
      </div>,
    );
  }

  return <div className="config-template">{configClass}</div>;
}
